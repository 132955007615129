/**
 * Define feature flag here as constants.
 *
 * export const KB_ENHANCEMENTS = 'jsd.px.kb-enhancements';
 *
 * Use them in react components.
 * import { KB_ENHANCEMENTS } from 'feature-flags';
 * import { FeatureFlag } from '@atlassian/help-center-common-component/feature-flag';
 *
 * <FeatureFlag name={KB_ENHANCEMENTS}>
 *  I will be shown in true :-)
 * </FeatureFlag>
 *
 * ========== NOTE ===================
 * BE CAREFUL ADDING FEATURE FLAGS, IF YOU RENAME THEM IT WILL CHANGE THE ANALYTIC CONTEXT
 * NAME AND NO LONGER SEND THE SAME CONTEXT ATTRIBUTE NAME.
 * ===================================
 */

import FeatureGates from '@atlaskit/feature-gate-js-client';
import { isFeatureEnabled } from '@atlassian/help-center-common-util/feature-manager';
// Be careful when using these constants directly. In most cases, you should use the function.
export const RICH_TEXT_AREA_FIELD = 'jsd.use-adf-default-value-for-textarea';
export const PORTAL_WRM_OPTIMIZATIONS = 'jsd-dw-portal-wrm-optimisations';
export const ENHANCED_RENDER_EXPERIENCE_FOR_KNOWLEDGE_ARTICLES =
    'jsm-enhanced-render-experience-for-knowledge-articles';
const JSM_ENABLE_ANONYMOUS_CUSTOM_UI = 'fe-jsm-forge-enable-anonymous-custom-ui';
const IS_APOLLO_STARGATE_PATH_ENABLED = 'jsd-apollo-stargate-path_cbi21';

const PROFORMA_HARMONISATION_MAIN = 'proforma-harmonisation-main_ca6ff';

const ENABLE_KB_ARTICLES_OPEN_IN_SAME_TAB = 'make-kb-articles-open-in-same-tab';

const FORGE_UI_KIT_2 = 'jsm-forge-ui-kit-2_pb6hu';

const JSM_PORTAL_CUSTOM_THEMING = 'jsm-portal-custom-theming';

export const UNIFIED_HELP_HELP_CENTER_EXPERIENCE = 'help-center-experience-for-unified-help';
export const UNIFIED_HELP_REPORTING_PAGE = 'help_center_reporting_route';
export const UNIFIED_HELP_REPORTING_CONTENT_GAP = 'hc_reporting_content_gap';
export const UNIFIED_HELP_REQUEST_FORM_REORDERING = 'unified-help-request-form-reordering_q8s94';
export const UNIFIED_HELP_ADD_FILTER_FOR_CUSTOMER_ACCOUNTS = 'unified-help-add-filter-for-customer-accounts';

const UPDATED_CONTENT_CREATED_BY_ANYONE_FILTER = 'updated-content-for-create-by-anyone-filter';
const UNIFIED_HELP_JSM_REDIRECT_BANNER = 'reroute-jsm-help-center-to-unified-help-center_f9jqr';
const MULTI_HC_KOSH_INTEGRATION = 'multi_help_center_kosh_integration';
const BASIC_HC_CALLS_JIRA = 'basic_hc_calls_jira';

const JCSM_CS_MASTER_FLAG = 'jcs_master_flag';

export const HC_CONVERSATION_BACKGROUND_COLOR_CHANGES = 'hc-conversation-background-color-changes-enabled';
export const JSM_VIRTUAL_AGENT_ANONYMOUS_ACCESS = 'anonymous_virtual_agent_access';
export const JSM_VIRTUAL_AGENT_ON_HELPCENTER = 'jsm-virtual-agent-and-ai-answers-on-helpcenter_i4mul';
export const JSM_VIRTUAL_AGENT_ON_HELPCENTER_TEST_CONERSATION = 'jsm-virtual-agent-on-helpcenter-test-conversation';

export const JSM_VIRTUAL_AGENT_ON_HELPCENTER_ESCAPE_HATCH = 'jsm_virtual_agent_on_helpcenter_escape_hatch';
export const USE_REACT_18_CREATEROOT = 'use_react-18_createroot';

export const USE_SHA256_FOR_MEDIA = 'use-sha256-for-media_wfgmf';

export const CMDB_TOTAL_COUNT_DEPRECATION = 'portal_assets_rearch_total_count_field_deprecation';
const JSM_FIX_SIGN_UP_ROUTING_FOR_MULTI_HC = 'fix-signup-routing-for-multi-hc_bi7d1';
export const REQUEST_CREATE_FORM_MANDATORY_INSTRUCTIONS = 'show-instructions-for-mandatory-fields-portal_9vzob';
export const REMOVE_MANAGE_COLUMNS_FROM_DROPDOWN_TO_SEPERATE_BUTTON =
    'remove-manage-columns-from-drodown-to-seperate-button_oi3ap';
export const SEND_REGION_IN_BROWSER_METRICS = 'send-region-in-browser-metrics_q7j37';

export const REMOVE_UNWANTED_SHARED_PORTAL_CODE_USAGE = 'remove-unwanted-shared-portal-code-in-fe_nyrua';
const ADVANCED_HELP_CENTER_CUSTOMIZATION_EDIT_PAGE = 'advanced_help_center_customization_edit_page';
const ENABLE_RETRIEVING_RELAY_TRACE_ID = 'enable_retrieving_relay_traceid';
const UPDATE_TEXT_FIELD_PACKAGE_VERSION_FOR_INPUT_FIELD = 'update_text_field_package_version_for_input_field';
const REMOVE_TRANSLATIONS_FALLBACK = 'remove_translations_fallback';
const REPORTING_EXPERIENCE = 'reporting-experience-in-help-center';
const TUNING_RETRY_MECHANISM_FOR_MODELS_ENDPOINT = 'tuning_model_retries';
const VIRTUAL_AGENT_IN_SEARCH_DROPDOWN = 'jsm-virtual-agent-search-dropdown_9qz5h';
const FILTER_PREMIUM_SLA_ERRORS = 'filter_premium_sla_errors';
const HELP_CENTER_DROPDOWN_REDESIGN = 'help_center_dropdown_redesign';
const CUSTOMISATION_BUTTON_CHANGE_BOARDING = 'enable_customisation_button_change_boarding';
const PROJECT_MAPPING_ENABLED_IN_MULTI_HC = 'enable_project_mapping_for_help_center';
const RECENT_FORMS_ERROR_BOUNDARY = 'recent_forms_error_boundary';
const CANCLE_APPROVAL_ACTIVITY_HISTORY = 'start-1399-surface-cancel-approval-into-activity';
const SUMMARY_FIELD_A11Y_FIXES = 'summary_field_a11y_fixes';
const FILTER_NULL_TRACE_ID_FROM_GRAPHQL_CALLS = 'filter_null_trace_id_from_graphql_calls';
const JSM_RESTRICTED_REQUEST_TYPE_M2_BUGS = 'restricted_request_type_m2_bugs';
const USE_NEW_EDITOR_CARD_PROVIDER = 'use_new_editor_card_provider';
const VA_LOADER_MESSAGE_BASED_ON_TIME_INTERVAL = 'va_on_portal_ai_answer_time_interval_based_spinner';
const VIRTUAL_AGENT_REBRAND_TO_VIRTUAL_SERVICE_AGENT = 'virtual_agent_rebrand_to_virtual_service_agent';
const REMOVE_SLASH_FROM_HOMEPAGE_ROUTE = 'remove_slash_from_homepage_routes';
const ENABLE_CROSS_SITE_CONFLUENCE = 'enable-cross-site-confluence';
const HANDLE_FOLLOW_UP_QUERIES_ON_VA = 'handling_followupquery_on_feedback_response';
const JSM_SEARCH_UI_REDESIGN = 'jsm_help_center_search_ui_redesign';
const REMOVE_CREATE_REQUEST_REDUNDANT_MODELS = 'jsm-create-request-redundant-models-fe';

export const isRemoveManageColumnsFromDropdownToSeperateButtonEnabled = () =>
    isFeatureEnabled({
        key: REMOVE_MANAGE_COLUMNS_FROM_DROPDOWN_TO_SEPERATE_BUTTON,
        fallback: false,
    });

export const shouldUseSHA256 = () =>
    isFeatureEnabled({
        key: USE_SHA256_FOR_MEDIA,
        fallback: false,
    });

export const EMAIL_VERIFIED_SIGNAL_ON_CUSTOMERS_PAGE = 'sptn-1697-email-verified-signal-on-customers-page_v2g52';

export const isEmailVerifiedSignalEnabled = () =>
    isFeatureEnabled({
        key: EMAIL_VERIFIED_SIGNAL_ON_CUSTOMERS_PAGE,
        fallback: false,
    });

export const isWrmOptimizationsEnabled = () =>
    isFeatureEnabled({
        key: PORTAL_WRM_OPTIMIZATIONS,
        fallback: false,
    });

export const isRichTextAreaEnabled = () =>
    isFeatureEnabled({
        key: RICH_TEXT_AREA_FIELD,
        fallback: false,
    });

export const isEnhanceRenderExperienceForKnowledgeArticlesEnabled = () =>
    isFeatureEnabled({
        key: ENHANCED_RENDER_EXPERIENCE_FOR_KNOWLEDGE_ARTICLES,
        fallback: false,
    });

export const isAnonymousCustomUIEnabled = () =>
    isFeatureEnabled({
        key: JSM_ENABLE_ANONYMOUS_CUSTOM_UI,
        fallback: false,
    });

export const isApolloStargatePathEnabled = () =>
    isFeatureEnabled({
        key: IS_APOLLO_STARGATE_PATH_ENABLED,
        fallback: false,
    });

export const isProformaHarmonisationFFEnabled = () =>
    isFeatureEnabled({
        key: PROFORMA_HARMONISATION_MAIN,
        fallback: false,
    });

export const islooselyLazyJsErrorsToSentryEnabled = () =>
    FeatureGates.checkGate('throw_lazy_error_boundary_js_errors_to_sentry');

export const isCustomThemingEnabled = () =>
    isFeatureEnabled({
        key: JSM_PORTAL_CUSTOM_THEMING,
        fallback: false,
    });

export const isUnifiedHelpReportingPageEnabled = () => {
    return FeatureGates.checkGate(UNIFIED_HELP_REPORTING_PAGE);
};

export const isProjectMappingInHelpCenterEnabled = () => {
    return FeatureGates.checkGate(PROJECT_MAPPING_ENABLED_IN_MULTI_HC);
};

export const isUnifiedHelpReportingContentGapEnabled = () => {
    return isFeatureEnabled({
        key: UNIFIED_HELP_REPORTING_CONTENT_GAP,
        fallback: false,
    });
};

export const isUnifiedHelpRequestFormReorderingEnabled = () => {
    return isFeatureEnabled({
        key: UNIFIED_HELP_REQUEST_FORM_REORDERING,
        fallback: false,
    });
};

export const isUnifiedHelpAddFilterForCustomerAccounts = () => {
    return FeatureGates.checkGate(UNIFIED_HELP_ADD_FILTER_FOR_CUSTOMER_ACCOUNTS);
};

export const isUIKit2Enabled = () => {
    return isFeatureEnabled({
        key: FORGE_UI_KIT_2,
        fallback: false,
    });
};

export const isUpdatedContentForCreatedByAnyoneFilter = () => {
    return isFeatureEnabled({
        key: UPDATED_CONTENT_CREATED_BY_ANYONE_FILTER,
        fallback: false,
    });
};

export const isJSMRedirectBannerEnabled = () => {
    return isFeatureEnabled({
        key: UNIFIED_HELP_JSM_REDIRECT_BANNER,
        fallback: false,
    });
};

export const isUnifiedHelpInJSMEnabled = () => {
    return isFeatureEnabled({
        key: UNIFIED_HELP_HELP_CENTER_EXPERIENCE,
        fallback: false,
    });
};

// Virtual Service Agent (JSM Flux) Feature flags

export const isJsmVirtualAgentOnHelpcenterEnabled = () =>
    isFeatureEnabled({
        key: JSM_VIRTUAL_AGENT_ON_HELPCENTER,
        fallback: false,
    });

export const isVAOnHelpCenterTestConversationEnabled = () => {
    return FeatureGates.checkGate(JSM_VIRTUAL_AGENT_ON_HELPCENTER_TEST_CONERSATION);
};

export const isJSMVirtualAgentAnonymousAccessEnabled = () => FeatureGates.checkGate(JSM_VIRTUAL_AGENT_ANONYMOUS_ACCESS);

export const isVaRebrandToVsaEnabled = () => {
    return FeatureGates.checkGate(VIRTUAL_AGENT_REBRAND_TO_VIRTUAL_SERVICE_AGENT);
};

export const isVaConversationHeaderEnabled = () => {
    return FeatureGates.checkGate('jsm-va-on-hc-show-title');
};

// JSM Boysenberry feature flags
export const isJsmCsMasterFlagEnabled = () => {
    return FeatureGates.checkGate(JCSM_CS_MASTER_FLAG);
};

export const isMultiHcKoshIntegrationEnabled = () => {
    return isFeatureEnabled({
        key: MULTI_HC_KOSH_INTEGRATION,
        fallback: false,
    });
};

export const isDefaultHCCallsJiraEnabled = () => {
    return FeatureGates.checkGate(BASIC_HC_CALLS_JIRA);
};

export const isSanitizationOfPortalDescriptionToRemoveTabIndexEnabled = () => {
    return FeatureGates.checkGate('sanitize_portal_card_description_tabi_index');
};

export const isNewReportingExperienceEnabled = () => {
    return FeatureGates.checkGate(REPORTING_EXPERIENCE);
};

export const isCrossSiteConnieConnectionEnabled = () => {
    return FeatureGates.checkGate(ENABLE_CROSS_SITE_CONFLUENCE);
};

export const isConversationBackgroundColorEnabled = () =>
    isFeatureEnabled({
        key: HC_CONVERSATION_BACKGROUND_COLOR_CHANGES,
        fallback: false,
    }) || isJsmVirtualAgentOnHelpcenterEnabled();

export const isKbArticlesSameTabRedirectionEnabled = () =>
    isFeatureEnabled({
        key: ENABLE_KB_ARTICLES_OPEN_IN_SAME_TAB,
        fallback: false,
    });

export const isCmdbTotalCountDeprecated = () => FeatureGates.checkGate(CMDB_TOTAL_COUNT_DEPRECATION);

export const isAdvancedCustomizationEnabled = () => {
    return FeatureGates.checkGate('advanced_help_center_customization');
};

export const isAdvancedCustomizationEditPageEnabled = () => {
    return FeatureGates.checkGate(ADVANCED_HELP_CENTER_CUSTOMIZATION_EDIT_PAGE);
};

export const shouldEnableRetrievingRelayTraceID = () => {
    return FeatureGates.checkGate(ENABLE_RETRIEVING_RELAY_TRACE_ID);
};

export const isFixSignUpRoutingForMultiHCEnabled = () =>
    isFeatureEnabled({
        key: JSM_FIX_SIGN_UP_ROUTING_FOR_MULTI_HC,
        fallback: false,
    });

export const isMandatoryInstructionsForRequestCreateFormEnabled = () =>
    isFeatureEnabled({
        key: REQUEST_CREATE_FORM_MANDATORY_INSTRUCTIONS,
        fallback: false,
    });

export const sendRegionAndPerimeterInBrowserMetrics = () => {
    return isFeatureEnabled({
        key: SEND_REGION_IN_BROWSER_METRICS,
        fallback: false,
    });
};

export const isNewTextFieldEnabled = () => {
    return FeatureGates.checkGate(UPDATE_TEXT_FIELD_PACKAGE_VERSION_FOR_INPUT_FIELD);
};

export const isSharedPortalCodeRemoved = () => {
    return isFeatureEnabled({
        key: REMOVE_UNWANTED_SHARED_PORTAL_CODE_USAGE,
        fallback: false,
    });
};

export const isUseReact18CreateRootEnabled = () => {
    return FeatureGates.checkGate(USE_REACT_18_CREATEROOT);
};

export const isRemoveTranslationsFallbackEnabled = () => {
    return FeatureGates.checkGate(REMOVE_TRANSLATIONS_FALLBACK);
};

export const isPortalCardMarkupFixEnabled = () => {
    return FeatureGates.checkGate('fix_portal_card_markup');
};

export const isVAOnHelpCenterEscapeHatchEnabled = () => {
    return FeatureGates.checkGate(JSM_VIRTUAL_AGENT_ON_HELPCENTER_ESCAPE_HATCH);
};

export const isHelpCenterNewDropdownEnabled = () => {
    return FeatureGates.checkGate(HELP_CENTER_DROPDOWN_REDESIGN);
};

export const isCustomiseDropDownChangeBoardingEnabled = () => {
    return FeatureGates.checkGate(CUSTOMISATION_BUTTON_CHANGE_BOARDING);
};

export const isFilterNullTraceIdFromGraphqlCallsEnabled = () => {
    return FeatureGates.checkGate(FILTER_NULL_TRACE_ID_FROM_GRAPHQL_CALLS);
};

export const isVirtualAgentInSearchDropdownEnabled = () =>
    isFeatureEnabled({
        key: VIRTUAL_AGENT_IN_SEARCH_DROPDOWN,
        fallback: false,
    });

export const isPremiumSLAErrorFilterEnabled = () => {
    return FeatureGates.checkGate(FILTER_PREMIUM_SLA_ERRORS);
};

export const isRecentFormsErrorBoundaryEnabled = () => {
    return FeatureGates.checkGate(RECENT_FORMS_ERROR_BOUNDARY);
};

export const isRetryMechanismTuningEnabled = () => {
    return FeatureGates.checkGate(TUNING_RETRY_MECHANISM_FOR_MODELS_ENDPOINT);
};

export const isCancelApprovalActivityStreamEnabled = () => {
    return FeatureGates.checkGate(CANCLE_APPROVAL_ACTIVITY_HISTORY);
};

export const isNewSummaryFieldA11yEnabled = () => {
    return FeatureGates.checkGate(SUMMARY_FIELD_A11Y_FIXES);
};

export const isRestrictedRequestTypeM2BugsEnabled = () => {
    return FeatureGates.checkGate(JSM_RESTRICTED_REQUEST_TYPE_M2_BUGS);
};
export const isUseNewEditorCardProviderEnabled = () => {
    return FeatureGates.checkGate(USE_NEW_EDITOR_CARD_PROVIDER);
};

export const isVATimeIntervalBasedLoaderMessageEnabled = () => {
    return FeatureGates.checkGate(VA_LOADER_MESSAGE_BASED_ON_TIME_INTERVAL);
};

export const isVAFollowupQueryOnlyForPositiveResponseEnabled = () => {
    return FeatureGates.checkGate(HANDLE_FOLLOW_UP_QUERIES_ON_VA);
};

export const isSlashRemovedFromHelpCenterRoute = () => {
    return FeatureGates.checkGate(REMOVE_SLASH_FROM_HOMEPAGE_ROUTE);
};

export const isSearchUIRedesignEnabled = () => {
    return FeatureGates.checkGate(JSM_SEARCH_UI_REDESIGN);
};

export const isRemoveRedundantModelsCreateRequest = () => {
    return FeatureGates.checkGate(REMOVE_CREATE_REQUEST_REDUNDANT_MODELS);
};
